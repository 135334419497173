.alert {
    position: absolute;
    padding: .75rem 1.25rem;
    border: 1px solid transparent;
    text-align: center;
    top: 0;
    width: 100%;
    left: 0;
    font-size: 18px;
    &>ul {
        list-style: none;
        font-size: 15px;
    }
}

.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.alert-danger, .alert-error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}

.bd-example>.alert+.alert, .bd-example>.nav+.nav, .bd-example>.navbar+.navbar, .bd-example>.progress+.btn, .bd-example>.progress+.progress {
    margin-top: 1rem;
}