@import '~sl-admin/dist/ng-admin.min.css';
@import 'flashMessages';
@import url(https://fonts.googleapis.com/css?family=Roboto&subset=latin);

body.verifications-wrapper {
    padding-top: 10px;
    background-color: #fff;
    text-align: center;
    font-family: Roboto;
    margin: 0 auto;

    .hidden {
        display: none !important;
    }

    img.cater-logo {
        width: 404px;
        height: 111px;
        margin-bottom: 10%;
    }

    h1.title {
        height: 35px;
        color: #6D6D6D;
        font-family: Roboto;
        font-size: 30px;
        font-weight: 900;
        line-height: 35px;
        text-align: center;
    }

    h2.description {
        color: #4D4D4D;
        font-family: Roboto;
        font-size: 20px;
        text-align: center;
        padding: 0 25%;
    }

    div.panel-body {
        padding-top: 25px;

        div.form-input {
            width: 100%;
            margin-bottom: 12px;
            text-align: center;
            label {
                position: absolute;
                margin: 2px 0 0 -352px;
                font-size: 10px;
                font-weight: 400;
                color: #888888;
                @media screen and (max-width: 446px) {
                    margin: 2px 0 0 -270px;
                }
            }
            input {
                height: 48px;
                width: 100%;
                border: 2px solid #404040;
                border-radius: 6px;
                background-color: #F3F3F3;
                padding: 10px;
                font-size: 16px;
                color: #404040;
                font-weight: 400;
                transition: opacity 0.25s ease-in-out, color 0.25s ease-in-out;

                &:invalid + label {
                    opacity: 0;
                    transition: opacity 0.25s ease-in-out, color 0.25s ease-in-out;
                }

                &:focus {
                    outline:none;

                    &::-webkit-input-placeholder {
                        color: transparent;
                        transition: color 0.25s ease-in-out;
                    }
                    & + label {
                        opacity: 1;
                        transition: opacity 0.25s ease-in-out, color 0.25s ease-in-out;
                    }
                }

                &::-webkit-input-placeholder {
                    font-weight: 400;
                    transition: color 0.25s ease-in-out;
                }

                @media screen and (max-width: 446px) {
                    width: 100%;
                }
            }
        }

        div.submit-button {
            width: 100%;
            margin-top: 68px;
            button[type=submit] {
                height: 50px;
                width: 278px;
                border-radius: 4px;
                background-color: #FCA687;
                box-shadow: 0 0 6px 0 rgba(0,0,0,0.12), 0 2px 6px 0 rgba(0,0,0,0.24);
                color: #FFFFFF;
                font-family: Roboto;
                font-size: 18px;
                font-weight: 500;
                letter-spacing: 0.64px;
                line-height: 21px;
                text-align: center;
                text-transform: uppercase;
                cursor: pointer;
            }
        }

        .login-center {
            position: relative;
            z-index: 1;
            display: inline-block;
            margin: 1em;
            max-width: 350px;
            width: calc(100% - 2em);
        }

        .vertical-narrow {
            margin: 0.5em 1em;
        }

        .contact100-form-checkbox {
            display: inline-block;
            width: calc(50% - 1em);
        }

        .check-separator {
            margin-left: 1em;
            width: calc(50% - 2em);
        }

        /* remember me stuff */
        div.remember-checkbox {
            line-height: 24px;
            font-size: 16px;
            box-sizing: border-box;
            display: inline-block;

            input[type="checkbox"] {
                opacity: 0;
            }

            label {
                position: relative;
                display: inline-block;

                /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
                padding-left: 22px;
            }
        }

        .remember-checkbox label::before,
        .remember-checkbox label::after {
            position: absolute;
            content: "";

            /*Needed for the line-height to take effect*/
            display: inline-block;
        }

        /*Outer box of the fake checkbox*/
        .remember-checkbox label::before{
            height: 16px;
            width: 16px;

            border: 1px solid;
            left: 0px;

            /*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
             *to vertically center it.
             */
            top: 3px;
        }

        /*Checkmark of the fake checkbox*/
        .remember-checkbox label::after {
            height: 5px;
            width: 9px;
            border-left: 2px solid;
            border-bottom: 2px solid;

            transform: rotate(-45deg);

            left: 4px;
            top: 7px;
        }

        /*Hide the checkmark by default*/
        .remember-checkbox input[type="checkbox"] + label::after {
            content: none;
        }

        /*Unhide on the checked state*/
        .remember-checkbox input[type="checkbox"]:checked + label::after {
            content: "";
        }

        /*Adding focus styles on the outer-box of the fake checkbox*/
        .remember-checkbox input[type="checkbox"]:focus + label::before {
            outline: rgb(59, 153, 252) auto 5px;
        }
    }

    .vs-container {
        width: 100%;
        min-height: 10vh;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 5px;
        background: #fff;

        .vs-wrap {
            width: 454px;
            background: #fff;
            padding-bottom: 5px;
            padding-top: 35px;
            &.login {
                width: 390px;
                .vs-section {
                    .vs-title {
                        color: #2A2A2A;
                        line-height: 1;
                        font-weight: 900;
                        padding: 25px 0;
                        font-size: 34px;
                    }
                }
            }
            .vs-section {
                width: 100%;
                .vs-hi-image {
                    display: block;
                    height: 200px;
                    width: 197px;
                    border-radius: 50%;
                    overflow: hidden;
                    margin: 0 auto;
                    margin-bottom: 60px;
                    &.not-curved {
                        border-radius: 0 !important;
                        width: 100%;
                        margin-bottom: 0;
                        height: 115px;
                    }
                    img {
                        width: 100%;
                        vertical-align: middle;
                        border-style: none;
                    }
                }
                .vs-cater-image {
                    display: block;
                    height: 41px;
                    width: 145px;
                    margin: 0 auto;
                    margin-bottom: 6px;
                    img {
                        width: 100%;
                        vertical-align: middle;
                        border-style: none;
                    }
                }
                .vs-title {
                    display: block;
                    font-family: Roboto;
                    font-size: 41px;
                    color: #6D6D6D;
                    line-height: 1.2;
                    text-align: center;
                    font-weight: 500;
                }
                .vs-paragraph {
                    width: 100%;
                    position: relative;
                    margin-bottom: 40px;
                    margin-top: 50px;
                    color: #6D6D6D;
                    font-family: Roboto;
                    &.vs-footer {
                        margin-top: 5em;
                    }
                    h3 {
                        font-weight: 500;
                        &.description {
                            font-size: 16px;
                            letter-spacing: 0;
                            line-height: 19px;
                            text-align: center;
                        }
                    }
                    p.description {
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 14px;
                        text-align: center;
                        color: #2A2A2A;
                    }
                }
                .vs-link-to-app {
                    width: 100%;
                    display: -webkit-box;
                    display: -webkit-flex;
                    display: -moz-box;
                    display: -ms-flexbox;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    margin-top: 60px;
                    button {
                        font-family: Roboto;
                        font-size: 21px;
                        color: #fff;
                        line-height: 1.2;
                        font-weight: 600;
                        display: -webkit-box;
                        display: -webkit-flex;
                        display: -moz-box;
                        display: -ms-flexbox;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0 20px;
                        width: 100%;
                        height: 50px;
                        background-color: #FCA687;
                        border-color: #fca687;
                        border-radius: 10px;
                        box-shadow: 0 10px 30px 0px rgba(252,166,135, 0.5);
                        -moz-box-shadow: 0 10px 30px 0px rgba(252,166,135, 0.5);
                        -webkit-box-shadow: 0 10px 30px 0px rgba(252,166,135, 0.5);
                        -o-box-shadow: 0 10px 30px 0px rgba(252,166,135, 0.5);
                        -ms-box-shadow: 0 10px 30px 0px rgba(252,166,135, 0.5);
                    }
                }
            }
        }
    }

}